body {
    width: 100%;
    height: 100%;
    /*border: 1px red solid;*/
}

.div1 {
    width: 40px;
    height: 40px;
    background-color: black;
    /*border: 3px black solid;*/
    position: relative;
    margin: auto;
    animation: square-to-circle 20s .0s infinite cubic-bezier(1,.0015,.0295,1.225) alternate-reverse;
}

.div2 {
    width: 40px;
    height: 40px;
    background-color: black;
    /*border: 3px black solid;*/
    position: relative;
    margin: auto;
    animation: square-to-circle2 20s 0s infinite cubic-bezier(1,.015,.295,1.225) alternate-reverse;
}

#one {
    top: 75px;
}

#two {
    top: 75px;
    right: 40px;
}

#three {
    top: 75px;
}

#four {
    bottom: 5px;
    left: 40px;
}

@keyframes square-to-circle {
    0%  {
        border-radius:0 0 0 0;
        background-color:;
        transform:rotate(45deg);
    }
    20%  {
        border-radius:0 0 0 0;
        background:;
        transform:rotate(45deg);
    }
    25%  {
        border-radius:50% 0 0 0;
        transform:rotate(135deg);
    }
    45%  {
        border-radius:50% 0 0 0;
        transform:rotate(135deg);
    }
    50%  {
        border-radius:50% 50% 0 0;
        transform:rotate(180deg);
    }
    70%  {
        border-radius:50% 50% 0 0;
        transform:rotate(180deg);
    }
    75%  {
        border-radius:50% 50% 50% 0;
        transform:rotate(315deg);
    }
    95%  {
        border-radius:50% 50% 50% 0;
        transform:rotate(315deg);
    }
    100% {
        border-radius:50%;
        transform:rotate(404deg);
    }
}

@keyframes square-to-circle2 {
    0%  {
        border-radius:0 0 0 0;
        transform:rotate(45deg);
    }
    20% {
        border-radius:0 0 0 0;
        transform:rotate(45deg);
    }
    25%  {
        border-radius:0 0 50% 0;
        transform:rotate(135deg);
    }
    45%  {
        border-radius:0 0 50% 0;
        transform:rotate(135deg);
    }
    50%  {
        border-radius:0 0 50% 50%;
        transform:rotate(180deg);
    }
    70% {
        border-radius: 0 0 50% 50%;
        transform: rotate(180deg);
    }
    75%  {
        border-radius:50% 0 50% 50%;
        transform:rotate(315deg);
    }
    95%  {
        border-radius:50% 0 50% 50%;
        transform:rotate(315deg);
    }
    100% {
        border-radius:50%;
        transform:rotate(404deg);
    }
}





