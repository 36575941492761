body {
  /*font-family: "Feather Bold", sans-serif; !* Use "Feather Bold" as the font family *!*/
  margin: 0;
  /*overflow-x: hidden;*/

  /*font-family: "DIN Next Rounded LT W01 Regular", sans-serif;*/
  font-family: 'Calibre', sans-serif; /* Fallback to sans-serif if Calibre is not available */

  -webkit-font-smoothing: antialiased;

  /*border: 5px blue solid;*/

}

#root {
  /*border: 5px blue solid;*/
}

/** {*/
/*  outline: 1px solid #f00 !important;*/
/*  opacity: 1 !important;*/
/*  visibility: visible !important;*/
/*}*/