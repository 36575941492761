.leaderboard {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff; /* Add a white background */
    /*box-shadow: 0 2px 4px rgba(0,0,0,0.1); !* Add a subtle shadow to lift the container *!*/
    border-radius: 8px; /* Smooth out the corners */
    overflow-x: hidden;
    /*min-height: 90vh;*/
    /*border: 1px red solid;*/
}
.leaderboard-header {
    /*position: fixed;*/
    width: 100%;
    max-width: 900px;

    top: 0;
    background-color: white; /* or any color that matches your design */
    z-index: 10; /* To ensure it stays above other content */
    /*padding: 10px;*/
    /*height: 120px;*/
    border-bottom: 1px solid #eee; /* Optional, for visual separation */

    /*font-family: "DIN Next Rounded LT W01 Medium", serif;*/

    /*border: 1px #1b2777 solid;*/
}

.leaderboard h1 {
    /*padding: 0 10px; !* This ensures the text does not touch the lines *!*/
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
}

.leaderboard h2 {
    font-size: 1.5em;
    color: #666;
    /*margin-bottom: 1em;*/
}

.leaderboard-list {
    list-style: none;
    /*border: 1px red solid;*/

}

.leaderboard-entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0px;
    padding-left: 40px;
    /*background-color: aliceblue;*/
    cursor: pointer;
    margin: 5px 50px 5px 5px;
    border: 2px solid rgb(255, 255, 255);
    border-radius: 12px;

    /*height: 200px;*/
    font-family: "DIN Next Rounded LT W01 Medium", serif;
}

.leaderboard-entry:hover {
    background-color: #f0f0f0;
}

.profile-pic-container {
    flex: 0 0 50px;
    margin-right: 10px;
}

.profile-svg {
    width: 100%;
    height: 100%;
}

.rank, .name {
    flex: 1;
    text-align: center;
    font-size: 1.1em;
    color: #555;
    /*border: 1px red solid;*/
    /*height: 35px;*/
}


.rank, .rank-icon {
    flex: 1;
    text-align: center;
    font-size: 1.1em;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rank-icon {
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
}

.profile-svg {
    position: relative;
    /*top: 8px;*/
}

.profile-svg, .rank-icon {
    margin-top: auto;
    margin-bottom: auto;
}

/* Ensure text ranks are vertically centered */
.rank {
    display: flex;
    align-items: center;
    justify-content: center;
    /*border: 1px red solid;*/

}

.name {
    flex: 9; /* Give more space to the name */
    text-align: left;
}

.score {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.1em;
    color: #555;
    /*border: 1px red solid;*/
    position: relative;
    /*top: 8px;*/
    /*padding: 5px;*/
    margin-top: auto;
}

.active-user-row {
    background-color: rgb(230, 255, 221);
    color: rgb(28, 176, 246);
    border: 2px solid rgb(181, 255, 132);
}

.active-user-row:hover {
    background-color: rgb(219, 252, 207);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .leaderboard-header {
        width: calc(100vw - 90px);
        padding: 20px;
    }
    .leaderboard {
        padding: 0px;
    }
    .leaderboard-entry {
        padding-left: 0px;
    }

    /*.leaderboard-entry {*/
    /*    flex-direction: column;*/
    /*    align-items: flex-start;*/
    /*}*/

    .rank, .name, .score {
        text-align: left;
        margin-top: 5px;
    }

    .profile-pic-container {
        margin-bottom: 10px;
    }
}
