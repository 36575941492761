/* HowItWorks.css */

/* Container styles */
.how-it-works-container {
  max-width: 900px;
  margin: 0 auto;

  padding: 20px;
  background-color: #fff;
  border-radius: 8px;

  overflow-x: hidden;
}

/* Heading styles */
.how-it-works-heading {

  color: rgb(53, 55, 64);
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
  /*border: 1px red solid;*/
  /*font-family: "DIN Next Rounded LT W01 Medium", serif;*/
}

/* Content styles */
.how-it-works-content {
  font-size: 16px;
  line-height: 1.5;
  color: #666;
}

.how-it-works-section {
  background-color: rgb(248, 250, 251); /* Grey background */
  border-radius: 12px; /* Optional: rounded corners */
  padding: 20px; /* Spacing inside the category container */
  margin-bottom: 20px;
}
/* Sub-heading styles */
.how-it-works-content h2 {
  /*font-family: "DIN Next Rounded LT W01 Medium", serif;*/
  color: rgb(53, 55, 64);
  line-height: 24px;
  font-size: 24px;
  /*color: #333;*/
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Emphasized text styles */
.how-it-works-content em {
  font-style: italic;
}

/* Paragraph styles */
.how-it-works-content p {
  color: rgb(53, 55, 64);
  font-size: 20px;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 16px;
}

/* List styles */
.how-it-works-content ul {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 16px;
}

/* List item styles */
.how-it-works-content li {
  margin-bottom: 8px;
}

/* Link styles */
.how-it-works-content a {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

/* Link hover styles */
.how-it-works-content a:hover {
  color: #0056b3;
}

/* Add any additional styling as needed */

