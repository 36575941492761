/* src/styles/Landing.css */
.landing-container {
    /*max-width: 1000px; !* Set a max width for the entire content *!*/
    height: 100vh;
    margin: 0 auto; /* Center the content horizontally */
    overflow: hidden;
    /*width: 400px;*/
    /*margin: 0;*/
    /*border: 1px red solid;*/
}

body {
    /*overflow: hidden;*/
    /*border: 1px #00ffc4 solid;*/
    /*width: 100px;*/

}

.landing-main-content {
    display: flex;
    flex-direction: row; /* Display columns side by side */
    justify-content: center; /* Center columns horizontally */
    align-items: center; /* Center columns vertically */
    width: 100vw;
    fill: rgb(28, 176, 246);
    height: 100%;
    top: 50%;
    right: 50%;
}

.left-column {
    display: flex;
    flex-direction: row; /* Display columns side by side */
    /*justify-content: center; !* Center columns horizontally *!*/
    align-items: center; /* Center columns vertically */

    flex: 2; /* Occupy 1/2 of the available space */
    height: 100%;
    color: rgb(255, 255, 255);
    background-color: rgb(0, 0, 0);
}

.right-column {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: rgb(255, 255, 255);
    /*border: 1px red solid;*/
}

.text-area {
    /*border: 1px red solid;*/
    margin-left: 40px;
    margin-right: 120px;
    max-width: 1000px;
}

.text-area-smallscreen {
    visibility: hidden;
    height: 0;
}

.text-area-smallscreen .landing-p-text {
    font-size: 24px;
}

.text-area-smallscreen .landing-b-text {
    font-size: 24px;
}


h3 {
    font-family: 'Calibre', sans-serif; /* Fallback to sans-serif if Calibre is not available */
    font-size: 76px;
    margin-bottom: -20px;
    font-weight: normal;
    color: #ffffff;
}

.landing-p-text {
    font-family: 'Calibre', sans-serif; /* Fallback to sans-serif if Calibre is not available */
    font-size: 30px;
    color: #ffffff;
}

.landing-b-text {
    font-family: 'Calibre', sans-serif; /* Fallback to sans-serif if Calibre is not available */
    font-size: 30px;
    color: #ffffff;
    font-weight: 700;
}

.superforecaster-link {
    color: #ee00ff; /* Blue color for the link */
    text-decoration: none; /* Removes underline from the link */
    font-weight: 500; /* Makes the link text bold */
}

.superforecaster-link:hover {
    color: #cf00de; /* Blue color for the link */
    text-decoration: underline; /* Underline on hover for emphasis */
}

.buttons {
    /*border: 1px red solid;*/
}

.blue-button {
    background-color: rgb(0, 0, 0);
    color: #fff; /* White text color */
    transition: background-color 0.3s, color 0.3s; /* Add a transition effect */
}

/* Style for the white button */
.white-button {
    background-color: #fff; /* White background color */
    color: black; /* Blue text color */
    border: 2px solid black; /* Blue border */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add a transition effect */
}

.white-button:hover {
    background-color: #f5f5f5; /* Blue background on hover */
}

/* Common button styles */
.button {
    /*font-family: "DIN Next Rounded LT W01 Regular", sans-serif;*/
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    z-index: 100;
    /*margin: 10px 10px;*/
}

.lottie-animation {
    width: 50vw; /* Take up 50% of the viewport width */
    height: auto; /* Keep the aspect ratio */
    /*border: 1px red solid;*/
}

.ourNames {
    /*border: 1px red solid;*/
    position: absolute;
    bottom: 10px;
    color: #a9a9a9;
}

.shape-animation {
    width: 200px;
    height: 300px;
    position: absolute;
    /*border: 1px red solid;*/
    top: 5%;
    right: calc(17% - 100px);
    z-index: 0;
}

b {
    font-weight: normal;
    color: #4b4b4b;
}

/* Media query for vertical design */
/*@media screen and (max-height: 800px) {*/
/*    .landing-main-content {*/
/*        flex-direction: column;*/
/*        text-align: center;*/
/*        min-height: 600px;*/
/*    }*/

/*    .shape-animation {*/
/*        visibility: hidden;*/
/*    }*/

/*    h3 {*/
/*        visibility: visible;*/
/*        font-size: 64px !important;*/
/*        line-height: normal;*/
/*    }*/

/*    .text-area p {*/
/*        visibility: hidden;*/
/*        line-height: 0;*/
/*    }*/

/*    .text-area {*/
/*        margin-right: 0;*/
/*        margin-left: 0;*/
/*    }*/

/*    .left-column {*/
/*        order: 1;*/
/*        width: 70%;*/
/*        height: 50vh;*/
/*        flex: 1;*/
/*        background-color: white;*/
/*    }*/

/*    .right-column {*/
/*        flex: 2;*/
/*        order: 2;*/
/*        width: 100vw;*/
/*        !*margin: 20px;*!*/
/*    }*/


/*    .buttons {*/
/*        margin-top: 0px;*/
/*        text-align: center;*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        align-items: center;*/
/*        justify-content: center;*/
/*    }*/

/*    h3 {*/
/*        font-size: 24px;*/
/*        color: rgb(63, 63, 63)*/
/*    }*/

/*    .button {*/
/*        padding: 10px 10px;*/
/*        text-decoration: none;*/
/*        margin: 5px 0;*/
/*    }*/
/*}*/

/* Media query for vertical design */
/*@media screen and (max-height: 500px) {*/
/*    .landing-main-content {*/
/*        flex-direction: column;*/
/*        text-align: center;*/
/*        overflow: hidden;*/
/*    }*/

/*    .text-area {*/
/*        height: 0;*/
/*        visibility: hidden;*/
/*        color: white;*/
/*    }*/

/*    .left-column {*/
/*        flex: 1;*/
/*        visibility: hidden;*/
/*    }*/

/*    .right-column {*/
/*        position: absolute;*/
/*        top: 0;*/
/*        order: 0;*/
/*        height: 100vh;*/
/*        margin: 0 0;*/
/*    }*/
/*    h3 {*/
/*        visibility: hidden;*/
/*    }*/

/*    .ourNames {*/
/*        visibility: hidden;*/
/*    }*/
/*}*/

/* Media query for responsive design */
@media screen and (max-width: 1000px) {
    .landing-container {
        height: 90vh;
    }
    .landing-main-content {
        flex-direction: column; /* Stack columns on top of each other */
        text-align: center;
        /*min-height: 600px;*/
        height: 90%;
        /*margin-right: 90px;*/
        /*border: 1px #00a6ff solid;*/
        display: flex;
        align-items: center;
        justify-content: center;
        /*overflow: hidden;*/
    }

    .shape-animation {
        visibility: hidden;
    }

    h3 {
        visibility: visible;
        /*border: 1px red solid;*/
        line-height: normal;
        font-size: 30px;
        font-weight: bold;
        color: rgb(63, 63, 63);
        margin-bottom: -100px;

    }

    .text-area {
        margin-right: 0;
        margin-left: 0;
        /*border: 1px #22ff00 solid;*/
        /*height: 50vh;*/
    }

    .text-area-smallscreen {
        /*border: 1px red solid;*/
        visibility: visible;
        height: auto;
    }

    .text-area-smallscreen .landing-p-text{
        color: #8a8a8a;
    }

    .text-area-smallscreen .landing-b-text{
        color: #8a8a8a;
        font-weight: 450;
    }

    .text-area-bigscreen p {
        visibility: hidden;
        line-height: 0;
    }

    .left-column {
        order: 1; /* Change the order of left column */
        width: 95%;
        /*height: 50vh;*/
        flex: 1; /* Occupy 1/2 of the available space */
        background-color: white;
        margin: 0 50px 0 50px;
        /*visibility: hidden;*/

        /*margin-top: 30px;*/
        line-height: 1.3;
        /*border: 1px #00ff04 solid;*/
    }

    .right-column {
        flex: 5;
        order: 3;
        width: 100%;
        margin: 0px;
        /*border: 1px #22ff00 solid;*/

    }

    .ourNames {
        /*border: 1px red solid;*/
        position: absolute;
        bottom: 1%;
        color: #a9a9a9;
    }

    .buttons {
        margin-top: 0px;
        /*width: 90vw;*/

        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /*border: 1px #22ff00 solid;*/
    }



    .button {
        /*width: 80vw;*/
        padding: 10px 10px;
        text-decoration: none;
        margin: 5px 0;
        /*border: 1px #22ff00 solid;*/

    }
}
