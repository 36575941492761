/* Tooltip.css */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer; /* Indicates an interactive element */
}

.tooltip-text {
    visibility: hidden;
    width: 200px; /* Adjust width based on content */
    max-width: 300px; /* Maximum width of the tooltip */
    background-color: #555; /* Dark background for the tooltip */
    color: #fff; /* White text color */
    border-radius: 6px; /* Rounded corners for the tooltip */
    padding: 8px 12px; /* Padding inside the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the element */
    /*right: 150px;*/
    margin-left: -75px; /* Center the tooltip */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s; /* Smooth transition for tooltip */
    font-size: 13px; /* Smaller font size */
    display: flex;
    align-items: center;
    text-align: center;
}

/*.tooltip::after { !* This is the arrow of the tooltip *!*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 100%; !* Bottom of the tooltip *!*/
/*    left: 50%;*/
/*    margin-left: -5px;*/
/*    border-width: 5px;*/
/*    border-style: solid;*/
/*    border-color: #555 transparent transparent transparent; !* Arrow color *!*/
/*}*/

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
