.home-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the layout takes the full viewport height */
  overflow-x: hidden; /* In case of horizontal overflow */
  /*border: 1px #bf00ff solid;*/
  /*width: 100%;*/

}

.main-content {
  /*border: 2px orange solid;*/
  margin-left: 250px;
  padding: 20px;
  flex-grow: 1;
}

@media (max-width: 768px) {
  .main-content {
    padding: 0px;
    margin-left: 90px;
    width: calc(100vw - 90px);
    overflow-x: hidden;
  }
}

/* Media query for screen width 480px or less */
@media (max-width: 480px) {
  .home-layout {
    /*flex-direction: column; !* Stack main content above the menu *!*/
    /*height: 100vh; !* Ensure full viewport height *!*/
    /*border: 1px red solid;*/
  }

  .main-content {
    /*margin-bottom: 60px; !* Provide space for the bottom menu *!*/
    margin-left: 0; /* Remove left margin if it's causing issues */
    width: 100%; /* Full width to accommodate the content */
    overflow-x: hidden; /* In case of horizontal overflow */
    /*border: 1px #003cff solid;*/
  }
}

/*@media (max-width: 480px) {*/
/*  .main-content {*/
/*    !*width: 100px;*!*/
/*    margin-left: 0;*/
/*    margin-bottom: 60px; !* Space for the bottom menu *!*/
/*  }*/
/*}*/
