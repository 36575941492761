/* Forecasts.css */

/* Overall layout */
.forecasts-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff; /* Add a white background */
    /*box-shadow: 0 2px 4px rgba(0,0,0,0.1); !* Add a subtle shadow to lift the container *!*/
    border-radius: 8px; /* Smooth out the corners */
    overflow-x: hidden;
    /*border: 1px #55ff00 solid;*/
}

.forecasts-header {
    /*font-family: "DIN Next Rounded LT W01 Medium", serif;*/
    color: rgb(53, 55, 64);
    font-size: 40px;
    line-height: 40px;
    font-weight: 600;
    /*border: 1px red solid;*/
}

.explanatory-text {
    color: rgb(53, 55, 64);
    font-size: 20px;
    line-height: 1.5em;
    -webkit-font-smoothing: antialiased;
}

.progress-container {
    position: sticky;
    top: 0; /* Adjust this value if you want some spacing from the top */
    background: linear-gradient(to bottom, white 65%, rgba(255, 255, 255, 0) 100%); /* Adjust the percentage for transition */
    z-index: 1000; /* Ensure it's above other content */
    padding: 15px 0 40px 0; /* Add some padding around the text and progress bar */
    /*border-bottom: 1px solid #e0e0e0; !* Optional: adds a line to separate from content below *!*/
}


.sticky-progress-container {
    position: fixed;
    max-width: 900px;
    width: calc(100vw - 330px);
    top: 0; /* Adjust this value if you want some spacing from the top */
    background: linear-gradient(to bottom, white 65%, rgba(255, 255, 255, 0) 100%); /* Adjust the percentage for transition */
    z-index: 1000; /* Ensure it's above other content */
    padding: 15px 0 40px 0; /* Add some padding around the text and progress bar */
    /*border-bottom: 1px solid #e0e0e0; !* Optional: adds a line to separate from content below *!*/
    /*border: 1px red solid;*/
}

.progress-bar-container {
    width: 100%; /* Full width of its container */
    background-color: #e0e0e0; /* Light grey background */
    border-radius: 5px; /* Rounded corners */
    margin: 5px 0 20px 0; /* Margin for spacing */
    height: 20px; /* Height of the progress bar */
    display: flex; /* Use flexbox for centering */
    /*align-items: center; !* Center items vertically *!*/
    /*justify-content: center; !* Center items horizontally *!*/
    /*border: 1px #00e1ff solid;*/
}

.progress-bar {
    height: 100%; /* Full height of its container */
    background-color: #4caf50; /* Green background for progress */
    border-radius: 5px; /* Rounded corners */
    transition: width 0.5s ease-in-out; /* Smooth transition for width changes */
}

.answered-count {
    text-align: center;
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    /* Add more styling as needed */
}

.time-remaining-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /*margin: 10px;*/
    padding: 10px;
    background-color: rgb(235, 250, 235); /* Grey background */
    /*background-color: #f5f5f5; !* Light grey background *!*/
    border: 1px solid rgb(210, 244, 211);; /* Light grey border */
    border-radius: 8px; /* Smooth out the corners */
    height: 100px;
}

.time-remaining-container strong,
.time-remaining-container span {
    margin: 5px 0; /* Spacing between 'Time remaining' and the actual time */
    font-size: 1em; /* Adjust the font size as needed */
}

.percentage-indicator {
    font-size: 1.5em; /* Large font size for the percentage */
    margin-left: 8px; /* Spacing from the slider */
    font-weight: bold; /* Bold font for the percentage */
}

/* Add this within your existing .forecasts-container styles */
.likelihood-description-container {
    margin-top: 20px; /* Space from the slider */
}

/* Add styles for time remaining and questions answered sections */
.countdown-timer {
    font-size: 20px; /* Increase font size for better visibility */
    margin-bottom: 10px; /* Add spacing between sections */
}

.answered-questions {
    font-size: 16px;
    color: #666; /* Use a softer color for text */
    margin-bottom: 20px; /* Add spacing between sections */
}

.introduction-section {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 8px;
    color: #333;
    font-size: 16px;
    line-height: 1.5;
}

.forecast-category {
    /*background-color: #f8f8f8; !* Grey background *!*/
    border-radius: 12px; /* Optional: rounded corners */
    /*padding: 20px; !* Spacing inside the category container *!*/
    margin-bottom: 100px; /* Spacing between each category container */
}

.category-title-container {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 20px 0;
    color: rgb(175, 175, 175);
}

.category-title-container::before,
.category-title-container::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgb(175, 175, 175);
    margin: 0 20px; /* Adjust the spacing around the lines */
}

.forecast-category-title {
    padding: 0 10px; /* This ensures the text does not touch the lines */
    background: #fff; /* Match the background color of your site to cover the line */
    position: relative;
    z-index: 1; /* Ensures the background color covers the line */
    font-weight: 600;
    text-align: center;
}

.category-explanation {
    color: rgb(175, 175, 175);
    font-size: 25px;
    line-height: 1.5em;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    font-weight: 500;
    padding: 0 30px 30px 30px;
}

.forecast-question {
    color: rgb(53, 55, 64);
    /*height: 200px;*/
    /*background-color: #f8f8f8; !* Grey background *!*/
    background-color: rgb(250, 250, 250); /* Grey background */
    /*border-bottom: 1px dashed #e1e1e1;*/
    border-radius: 12px; /* Optional: rounded corners */
    padding: 20px; /* Spacing inside the category container */
    margin-bottom: 50px;
}

.glossary-word {
    color: rgb(16, 163, 127); /* Or any color you'd like, specified in hex, rgb, or a named color */
    /*text-decoration: underline; !* Optional: if you want to indicate that it's interactive *!*/
    /*cursor: help; !* Optional: change cursor to indicate hover functionality *!*/
}

/* Typography improvements for hierarchy and readability */
.forecast-question h2 {
    color: rgb(75, 75, 75);
    margin-bottom: 10px; /* Increase space below the heading for better visual separation */
    font-size: 24px; /* Slightly larger to denote importance */
    font-weight: 500; /* Bold weight for headings */
}

.forecast-question p {
    color: rgb(75, 75, 75);
    font-size: 20px; /* Adjust font size for readability */
    line-height: 1.7; /* Improved line height */
    margin-bottom: 40px; /* Adjust bottom margin */
}

/* Enhancements for form elements */
.forecast-question input[type="range"] {
    -webkit-appearance: none; /* Remove default styling */
    width: 100%;
    margin: 18px 0; /* Increase vertical spacing */
    background: transparent; /* Remove default background */
}

/* Slider thumb */
.forecast-question input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(28, 176, 246);
    cursor: pointer;
}

.forecast-question input[type="range"]::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(28, 176, 246);
    cursor: pointer;
}

/* Slider track */
.forecast-question input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: #ddd;
    border-radius: 4px;
}

.forecast-question input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8px;
    background: #ddd;
    border-radius: 4px;
}

.slider-answered {
    /* Styles for when the slider has been interacted with */
}

.slider-unanswered {
    /* Different styles for when the slider has not been interacted with */
}

/* Enhance the likelihood percentage indicator */
.likelihood-percentage {
    font-weight: bold;
    color: #333;
}

/* Submit button */
.submit-button {
    /*box-sizing: border-box; !* New Property *!*/
    background-color: rgb(28, 176, 246);
    border-radius: 12px;
    color: white;
    cursor: pointer;
    padding: 10px;
    margin: 20px 0;
    text-align: center;

    font-size: 16px;
    font-weight: bold;

    width: 100%;
    height: 55px;

    border: 2px solid rgb(22, 138, 194);

    transition: background-color 0.15s ease-in-out;
}

.submit-button:hover {
    background-color: rgb(27, 164, 229);
}

/* Slider thumb */
.forecast-question input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(28, 176, 246);
    cursor: pointer;
    /* Center the thumb by moving it up by half its height */
    margin-top: -8.5px; /* Adjust this value if necessary */
}

.forecast-question input[type="range"]::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: rgb(28, 176, 246);
    cursor: pointer;
    /* There is no need to adjust for Firefox as it centers by default */
}

/* Slider track */
.forecast-question input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    background: #ddd;
    border-radius: 4px;
    /* Center the track */
    box-shadow: inset 0 1px 0 #fff, inset 0 -1px 0 #ddd;
}

.forecast-question input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8px;
    background: #ddd;
    border-radius: 4px;
    /* Adjust the box shadow to be similar to the WebKit style if needed */
}

/* Slider container */
.slider-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space between the unlikely label, slider, and percentage */
}

/* Slider labels */
.slider-label {
    font-size: 12px;
    color: #888;
    margin-right: 8px;
    width: 80px; /* Set a fixed width for alignment */
    text-align: right; /* Align text to the right for the first label */
}

.slider-label:last-child {
    margin-right: 0;
    margin-left: 8px;
    text-align: left; /* Align text to the left for the last label */
}

.likelihood-description h1 {
    font-weight: 500;
}

.success-message {
    margin-top: 20px;
    color: green;
    font-size: 16px;
    text-align: center;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.disabled .slider-container {
    background-color: #e0e0e0; /* Example color to indicate disabled state */
}

.expression-face svg {
    width: 50px; /* Adjust as needed */
    height: auto;
    display: block; /* or inline-block, depending on layout */
}


.modal {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 20% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 35%;
    text-align: center;
    border-radius: 8px;
    color: black;
}

p {
    color: black;
}

.close-btn {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close-btn:hover,
.close-btn:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}


/* Ensure content is not too wide on large screens */
@media (max-width: 768px) {
    .forecasts-container {
        padding: 25px; /* Increase padding on larger screens */
        /*width: 200px;*/
    }

    .forecast-question p {
    }
    .sticky-progress-container {
        width:calc(100vw - 140px);
        /*border: 1px #009dff solid;*/
    }
    .modal-content {
        width: 85vw;
    }
    }

@media (max-width: 480px) {
    .slider-label {
        visibility: hidden;
        width: 0;
    }
    .sticky-progress-container {
        width: calc(100vw - 50px);
    }

    .modal-content {
        width: 80vw;
    }

    /*.forecast-question input[type="range"] {*/
    /*    border: 1px red solid;*/
    /*}*/

    /*.forecast-question input[type="range"]::-webkit-slider-runnable-track {*/
    /*    width: 100%;*/
    /*    height: 8px;*/
    /*    background: #ddd;*/
    /*    border-radius: 4px;*/
    /*}*/
    }

/* Adding transitions for smooth visual effects */
* {
    /*transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;*/
}
