/* ../styles/Profile.css */

.profile-container {
  height: calc(100vh - 100px);
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between; /* This will push the logout button to the bottom */
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-header-text {
  color: rgb(53, 55, 64);
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}

.profile-name-email {
  text-align: left;
  flex-grow: 1; /* Adjust as needed */
}

.profile-picture {
  font-family: "DIN Next Rounded LT W01 Medium", serif;

  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  font-weight: bold;
  color: white;

  order: 2; /* Moves the picture to the right */

}

.score {
  font-size: 48px;
  margin: 20px 0;
}

.logout-button {
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  display: block;
  margin: 20px auto; /* Centers the button */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .profile-picture {
    margin-bottom: 20px;
  }
}
