.container {
  /*overflow-x: hidden;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  color: #2d333a;
  padding: 0 20px; /* added padding to container */
  /*border: 1px red solid;*/
}

.closeButton {
  position: absolute;
  top: 10px;
  left: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
}

.closeButton img {
  width: 20px; /* Or the size you prefer */
  height: 20px; /* Or the size you prefer */
}

.input {
  margin: 5px 0;
  padding: 15px;
  width: 330px;
  height: 55px;
  border-radius: 12px;
  border: 2px solid #ddd;
  background-color: #f8f8f8;
  box-sizing: border-box;
  font-size: 15px;
  letter-spacing: 1px;
}

.passwordInputContainer {
  position: relative;
  /*margin: 5px 0;*/
}

.input[type="password"] {
  padding-right: 50px; /* Adjust this value so it fits your toggle icon */
}

.togglePasswordButton {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  padding: 10px; /* Makes the button easier to click */
}

.button{
  /*box-sizing: border-box; !* New Property *!*/
  cursor: pointer;
  padding: 10px;
  border-radius: 3px;
  margin: 20px 0;
  text-align: center;

  font-size: 16px;
  font-weight: bold;

  width: 330px;
  height: 55px;

  border: 2px solid rgb(0, 0, 0);

  transition: background-color 0.15s ease-in-out;

}

.button:hover {
  background-color: rgb(47, 47, 47);
}

.red-button:hover {
  background-color: #f5f5f5; /* Blue background on hover */
}

.googleButton, .appleButton, .facebookButton {
  min-height: 55px; /* ensures that height does not go below 55px */
  display: flex;
  align-items: center; /* align items in the cross-axis (vertically centered) */
  justify-content: flex-start; /* align items in the main axis to the start (left-aligned) */
  padding-left: 0; /* Removed the padding left */
  box-sizing: border-box; /* New Property */
  cursor: pointer;
  /*padding: 10px;*/
  border-radius: 12px;
  margin: 5px 0;
  text-align: center;
  width: 330px;
  height: 55px;

  font-size: 16px;

  background-color: #fff;
  border: 2px solid #ddd;

  overflow: hidden; /* hides the overflow content */
  white-space: nowrap; /* stops the text from wrapping to the next line */
  text-overflow: ellipsis;

  transition: background-color 0.15s ease-in-out; /* Adjust delay as needed */
}

.googleButton:hover, .appleButton:hover, .facebookButton:hover {
  background-color: #f1f1f1; /* Change to the grey color you prefer */
}

.button {
  /*background-color: #10a37f;*/
  background-color: rgb(0, 0, 0);
  border-radius: 5px;
  color: white;
}

.red-button {
  background-color: #e51d1d;
  color: rgb(255, 255, 255); /* Blue text color */
  border: 2px solid #c21616; /* Blue border */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add a transition effect */
}

.red-button:hover {
  background-color: #d21717;
}

.googleButton {
  /*background-color: #db4437;*/
  color: #2f2f2f;
}

.facebookButton {
  /*background-color: #4267B2; !* Facebook's primary color *!*/
  color: #1f1f1f;
}

.appleButton {
  /*background-color: #000;*/
  color: black;
}

.buttonIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 55px; /* Adjust according to your design */
  height: 100%;
}

.buttonIcon {
  height: 20px;
  width: auto; /* Maintain aspect ratio */
}

.error {
  color: black;
}

.loginLink {
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
}

.loginLink a,
.loginLink a:link,
.loginLink a:visited,
.loginLink a:hover,
.loginLink a:active {
  color: rgb(24, 153, 214); /* Adjust color as needed */
  text-decoration: none; /* Removes underline */
}

.orContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 330px;
  margin: 15px 0;
}

.orLine {
  flex-grow: 1;
  height: 1px;
  background-color: #fff;
}

.orText {
  padding: 0 10px;
  background-color: #fff; /* same as your container background */
  font-size: 13px;
}

.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff; /* Add your preferred color */
  /*box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);*/
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Added styling for the verification sent and email verified sections */
.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.verification-container h1 {
  margin: 20px 0;
  font-size: 24px;
}

.verification-container p {
  margin-bottom: 20px;
  font-size: 16px;
}

.error-message {
  color: #d32f2f; /* Red color for errors */
  background-color: #f443361a; /* Light red background */
  border: 1px solid #f44336; /* Red border */
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
}

/* Loading Animation Styles */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 6px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}


/* Media Query for medium screens */
@media (max-width: 768px) {
  .input,
  .button,
  .googleButton,
  .appleButton,
  .facebookButton,
  .orContainer {
    /*width: 90%; !* Make width 100% for smaller screens *!*/
  }
}

/* Media Query for small screens */
@media (max-width: 480px) {
  .input,
  .button,
  .passwordInputContainer,
  .googleButton,
  .appleButton,
  .facebookButton,
  .orContainer {
    /*width: 100%; !* Make width 100% for small screens *!*/
  }

  .container {
    /*border: 1px red solid;*/
  }

  .input, .button {
    height: 45px; /* Reduced height for smaller screens */
  }

  .buttonIcon {
    height: 16px; /* Reduced icon size for smaller screens */
  }

  .orText, .loginLink {
    font-size: 12px; /* Reduced font-size for smaller screens */
  }
}
